import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  height: 100vh;
  background: linear-gradient(135deg, #7bb5f7 0%, #50f0cc 33%, #8ff3ab 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  h1 {
    max-width: 400px;
    margin-bottom: 0.25em;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.main};
  }
  h4 {
    font-weight: 700;
    color: ${({ theme }) => theme.palette.primary.main};
  }
  padding-bottom: 3em;
`
export const TextContainer = styled.div`
  max-width: 740px;
  margin: auto;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 540px;
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    max-width: 340px;
  }
`
