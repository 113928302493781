import React from "react"
import * as S from "./contact.styles.jsx"
import parse from "html-react-parser"

const Contact = ({title, subtitle, content}) => {
  return (
    <S.Wrapper contained maxWidth="md">
      <S.TextContainer>
        <h1>{title}</h1>
        <h4 style={{ marginBottom: "5em" }}>
          {subtitle}
        </h4>
        {content && parse(content)}
      </S.TextContainer>
    </S.Wrapper>
  )
}
export default Contact
